/** @format */

import * as React from 'react';
import { ProductHero } from '../../components';

export const Home = () => (
  <React.Fragment>
    <ProductHero />
  </React.Fragment>
);
